import React, { useEffect } from "react";
import { Container, Row, Col, Tabs, Tab } from "react-bootstrap";
import "../Styles/HomePage.css";
import Letstalk from "./Lets talk";
import "../Styles/Contact-us.css";
import { useState } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import countryData from "../Components/Countries.json";

const Contact = () => {
  const [formData, setFormData] = useState({
    issue: "",
    countryname: "",
    city: "",
    name: "",
    mobile: "",
    country: "",
    email: "",
    gatedcommunity: "",
    textmessage: "",
  });

  const [submitting, setSubmitting] = useState(false);
  const emailBody = `
      <div style="background-color: #f5f5f5; padding: 20px; font-family: Arial, sans-serif;">
          <h4 style="color: #333; font-size: 18px; margin-bottom: 10px;">Contact Us Form Response</h4>
          <div style="margin-bottom: 10px;">
              <strong style="color: #555;">Issues:</strong> ${formData.issue}
          </div>
     
          <div style="margin-bottom: 10px;">
              <strong style="color: #555;">City:</strong> ${formData.city}
          </div>
          <div style="margin-bottom: 10px;">
              <strong style="color: #555;">Name:</strong> ${formData.name}
          </div>
          <div style="margin-bottom: 10px;">
              <strong style="color: #555;">Mobile Number:</strong> ${formData.mobile}
          </div>
          <div style="margin-bottom: 10px;">
              <strong style="color: #555;">Email:</strong> ${formData.email}
          </div>
          <div style="margin-bottom: 10px;">
          <strong style="color: #555;">Gated Community Name:</strong> ${formData.gatedcommunity}
          </div>

          <div style="margin-bottom: 10px;">
              <strong style="color: #555;">Message:</strong>${formData.textmessage}
          </div>
        
      </div>
      
  
  
`;

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    setSubmitting(true);
    //support@honc.io
    axios
      .post("https://api.honc.io/admin/chatbot/send-email", {
        to: "support@honc.io",
        subject: "Contact us form response from website",
        // body: `
        //   <br />
        //   <h4>Issues: ${formData.issue}</h4>
        //   <h4>City: ${formData.city}</h4>
        //   <h4>Name: ${formData.name}</h4>
        //   <h4>Phone: ${formData.mobile}</h4>
        //   <h4>Email: ${formData.email}</h4>
        //   <h4>Message: ${formData.textmessage}</h4>
        //   <h4>Country: ${formData.countryname}</h4>
        //   <h4>GatedCommunity: ${formData.gatedcommunity}</h4>
        //   <br />`,
        body: emailBody,
      })
      .then((response) => {
        console.log("Email sent successfully:", response.data);

        Swal.fire(
          "Success",
          "Your response has been submitted successfully",
          "success"
        );

        setFormData({
          issue: "",
          countryname: "",
          city: "",
          name: "",
          mobile: "",
          country: "",
          email: "",
          gatedcommunity: "",
          textmessage: "",
        });
        setSubmitting(false);
      })
      .catch((error) => {
        console.error("Error sending email:", error);
        setSubmitting(false);
      });
  };
  function handleMobile() {
    const mobileNum = document.getElementById("mobile-num");
    mobileNum.style.border = "1px solid black";
  }

  return (
    <section className="Background">
      {/* <Container  className='p-0 overflow-hidden'> */}
      <div className="overflow-hidden p-0">
        <section className="container">
          <Row
            className="d-flex justify-content-center "
            style={{ paddingTop: "5%" }}
          >
            <Col
              lg={5}
              md={6}
              style={{
                position: "relative",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <div
                className="d-flex justify-content-center "
                style={{ flexDirection: "column" }}
              >
                <div className="circle-bg"></div>
                <div className="circle-background"></div>
                <Row className="mt-2" style={{ display: "flex" }}>
                  <h2
                    className="honc-heading d-flex "
                    style={{ color: "#B7AA98" }}
                  >
                    We'd love to{" "}
                  </h2>
                  <h2
                    style={{ lineHeight: 1, color: "#B7AA98" }}
                    className="honc-heading"
                  >
                    hear from You.
                  </h2>
                </Row>

                <Row className="mt-3">
                  <p style={{ color: "#FFFFFF" }} className="queries">
                    {" "}
                    Reach out to us at support@honc.io<br></br>
                    <br></br>Stay tuned for the latest updates and exclusive
                    offers.
                  </p>
                </Row>

                <Row className="mt-3" style={{}}>
                  <a href="#lets-talk" style={{ textDecoration: "none" }}>
                    {" "}
                    <Letstalk value="Let's talk" />
                  </a>
                </Row>
              </div>
            </Col>

            <Col
              lg={7}
              md={6}
              className="mt-0 d-flex justify-content-end align-items-center hello-honc-img"
            >
              <img src="./images/hello_honc.png" className="img-fluid" />
            </Col>
          </Row>
        </section>
        <section className="">
          <Col lg={12}>
            <Row className="car-road2">
              <img src="./images/car-road-2.png" className="img-fluid" />
            </Row>
          </Col>
        </section>
        <section className="container" id="lets-talk">
          <Row style={{}} className="">
            <Col
              style={{ paddingLeft: "3%", paddingTop: "2%" }}
              lg={6}
              className="contact-img"
            >
                   <Row className="d-flex " style={{ marginTop: "5%" }}>
                    <div className="d-flex">
                      <img src="./images/phone.png" className="phone-img" />
                      <h4
                        className="contact-num ms-2"
                        style={{ color: "#0D0D0D" }}
                      >
                        +91 9949177071
                      </h4>
                    </div>
                  </Row>
                  <Row className="d-flex " style={{ marginTop: "6%" }}>
                    <div className="d-flex ">
                      <img src="./images/email.png" className="email-img" />
                      <h4
                        className="contact-num ms-2"
                        style={{ color: "#0D0D0D" }}
                      >
                        support@honc.io
                      </h4>
                    </div>
                  </Row>
                  <Row
                    className="d-flex honc-address"
                    style={{ marginTop: "6%" }}
                  >
                    <div className="d-flex ">
                      <img
                        src="./images/location.png"
                        className="location-img"
                      />
                      <div
                        className="d-flex"
                        style={{ flexDirection: "column" }}
                      >
                        <h4
                          className="contact-num ms-2"
                          style={{ color: "#0D0D0D" }}
                        >
                          Address
                        </h4>
                        <span className="adress-details">
                          Block B, 4th Floor, honc office,<br></br> Q-City,
                          Sy.No.109,110,111/2,<br></br> Nanakramguda,
                          Gachibowli,<br></br> Serlingampally(M), Hyd-500032
                        </span>
                      </div>
                    </div>
                  </Row>
              {/*<p style={{ color: "black" }} className='here-to-help'>We value your feedback.</p>*/}
              {/* <Tabs
                defaultActiveKey="oman"
                id="uncontrolled-tab-example"
                className="mb-3"
                variant="underline"
              >
                                <Tab eventKey="oman" title="&nbsp;&nbsp;&nbsp;&nbsp;OMAN&nbsp;&nbsp;&nbsp;&nbsp;" tabClassName="text-black">
                  <Row className="d-flex " style={{ marginTop: "5%" }}>
                    <div className="d-flex">
                      <img src="./images/phone.png" className="phone-img" />
                      <h4
                        className="contact-num ms-2"
                        style={{ color: "#0D0D0D" }}
                      >
                        +968 9827 0568
                      </h4>
                    </div>
                  </Row>
                  <Row className="d-flex " style={{ marginTop: "6%" }}>
                    <div className="d-flex ">
                      <img src="./images/email.png" className="email-img" />
                      <h4
                        className="contact-num ms-2"
                        style={{ color: "#0D0D0D" }}
                      >
                        support@honc.io
                      </h4>
                    </div>
                  </Row>
                  <Row
                    className="d-flex honc-address"
                    style={{ marginTop: "6%" }}
                  >
                    <div className="d-flex ">
                      <img
                        src="./images/location.png"
                        className="location-img"
                      />
                      <div
                        className="d-flex"
                        style={{ flexDirection: "column" }}
                      >
                        <h4
                          className="contact-num ms-2"
                          style={{ color: "#0D0D0D" }}
                        >
                          Address
                        </h4>
                        <span className="adress-details">
                        Honc (Cultural Links Technology LLC),<br/> Off #31, FBF Commercial Complex,<br/> Al Murtafah Street, Al’Seeb, Sultanate of Oman
                        </span>
                      </div>
                    </div>
                  </Row>
                </Tab>
                <Tab eventKey="india" title="&nbsp;&nbsp;&nbsp;&nbsp;INDIA&nbsp;&nbsp;&nbsp;&nbsp;" tabClassName="text-black">
                  <Row className="d-flex " style={{ marginTop: "5%" }}>
                    <div className="d-flex">
                      <img src="./images/phone.png" className="phone-img" />
                      <h4
                        className="contact-num ms-2"
                        style={{ color: "#0D0D0D" }}
                      >
                        +91 9949177071
                      </h4>
                    </div>
                  </Row>
                  <Row className="d-flex " style={{ marginTop: "6%" }}>
                    <div className="d-flex ">
                      <img src="./images/email.png" className="email-img" />
                      <h4
                        className="contact-num ms-2"
                        style={{ color: "#0D0D0D" }}
                      >
                        support@honc.io
                      </h4>
                    </div>
                  </Row>
                  <Row
                    className="d-flex honc-address"
                    style={{ marginTop: "6%" }}
                  >
                    <div className="d-flex ">
                      <img
                        src="./images/location.png"
                        className="location-img"
                      />
                      <div
                        className="d-flex"
                        style={{ flexDirection: "column" }}
                      >
                        <h4
                          className="contact-num ms-2"
                          style={{ color: "#0D0D0D" }}
                        >
                          Address
                        </h4>
                        <span className="adress-details">
                          Block B, 4th Floor, honc office,<br></br> Q-City,
                          Sy.No.109,110,111/2,<br></br> Nanakramguda,
                          Gachibowli,<br></br> Serlingampally(M), Hyd-500032
                        </span>
                      </div>
                    </div>
                  </Row>
                </Tab>
              </Tabs> */}
            </Col>
            <Col style={{ backgroundColor: "white" }} lg={6}>
              <p
                style={{
                  color: "#0D0D0D",
                  paddingLeft: "3%",
                  paddingTop: "5%",
                }}
                className="Lets-Tak"
              >
                Let's Talk!
              </p>
              <form onSubmit={handleSubmit}>
                <div
                  className="d-flex justify-content-center"
                  style={{ flexDirection: "column" }}
                >
                  <div
                    style={{ position: "relative" }}
                    class="custom-dropdown d-flex justify-content-center"
                  >
                    <select
                      class="selectBox"
                      name="issue"
                      value={formData.issue}
                      onChange={handleInputChange}
                      placeholder="Issue"
                      required
                    >
                      <option value="" selected>
                        Select enquiry type *
                      </option>
                      <option value="Demo at my community">
                        Demo at my community
                      </option>
                      <option value="Partnership">Partnership</option>
                      <option value="Looking for a job">
                        Looking for a job
                      </option>
                      <option value="General enquiry">General enquiry</option>
                    </select>
                  </div>
                  <Row
                    style={{
                      paddingTop: "3%",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    {/* <Col lg={6} md={6} className='d-flex'>
                                            <input type="text" className=" input-country" name="countryname" value={formData.countryname} onChange={handleInputChange} placeholder="Country" ></input>
                                        </Col> */}
                    <Col lg={12} md={12} className="d-flex">
                      <input
                        type="text"
                        className="input-text input-city"
                        name="city"
                        value={formData.city}
                        onChange={handleInputChange}
                        placeholder="City"
                      ></input>
                    </Col>
                  </Row>
                  <Row className="name-input d-flex justify-content-center">
                    <input
                      type="text"
                      className="input-text-name"
                      name="name"
                      value={formData.name}
                      onChange={handleInputChange}
                      placeholder="Name"
                    ></input>
                  </Row>
                  <Row
                    style={{
                      paddingTop: "3%",
                      display: "flex",
                      flex: 1,
                      justifyContent: "space-evenly",
                    }}
                  >
                    <div className="col-lg-12 col-md-12 col-sm-6 col-xs-6 mobile-input1">
                      <div
                        className="rounded-input telephone-num"
                        id="mobile-num"
                        style={{ display: "flex", border: "1px solid #707070" }}
                        onClick={handleMobile}
                      >
                        <div
                          className="country-code-dropdown"
                          style={{ border: "none" }}
                        >
                          {/* <select name="country" value={formData.country} className='countrycode'   onChange={handleInputChange}  id="color" style={{border:"none"}} required >
                                                    <option value="+91" selected>+91 (IN) </option>
  <option value="+1">+1 (US)</option>
  <option value="+44">+44 (UK) </option>
  <option value="+971">+971 (UAE)</option>

                                                    </select> */}
                          <select
                            name="country"
                            value={formData.country}
                            onChange={handleInputChange}
                            className="countrycode"
                            id="color"
                            style={{ border: "none" }}
                            required
                          >
                            <option value="+91">91 (IN) </option>
                            {countryData.map((country, index) => (
                              <option key={index} value={country.code}>
                                {country.code} ({country.abbreviation})
                              </option>
                            ))}
                          </select>
                        </div>
                        <div className="separator">|</div>
                        <input
                          type="tel"
                          id="phone"
                          placeholder="Enter your phone number *"
                          className="mobile-num"
                          style={{ border: "none" }}
                          name="mobile"
                          value={formData.mobile}
                          onChange={(e) => {
                            const numericValue = e.target.value.replace(
                              /\D/g,
                              ""
                            );
                            handleInputChange({
                              target: { name: "mobile", value: numericValue },
                            });
                          }}
                          required
                        />
                      </div>
                    </div>
                    <Col lg={12} md={12} className="d-flex email-input2">
                      <input
                        type="email"
                        className=" input-email"
                        name="email"
                        value={formData.email}
                        onChange={handleInputChange}
                        placeholder="Enter email id"
                      ></input>
                    </Col>
                  </Row>
                  <Row className="gated-input d-flex justify-content-center">
                    <input
                      type="text"
                      className="input-text-name"
                      name="gatedcommunity"
                      value={formData.gatedcommunity}
                      onChange={handleInputChange}
                      placeholder="Gated community name"
                    ></input>
                  </Row>
                  <Row className="review-text">
                    <textarea
                      id="w3review"
                      name="textmessage"
                      value={formData.textmessage}
                      onChange={handleInputChange}
                      className="w3review"
                      placeholder="Write a  message"
                      rows="4"
                      cols="50"
                    ></textarea>
                  </Row>
                  <div
                    style={{
                      paddingLeft: "5%",
                      paddingTop: "3%",
                      paddingRight: "5%",
                      paddingBottom: "10%",
                    }}
                    className="row"
                  >
                    <input
                      type="Submit"
                      className="submit-btn1 d-flex justify-content-center"
                      value={submitting ? "Submitting..." : "Submit"}
                      disabled={submitting}
                    />
                  </div>
                </div>
              </form>
            </Col>
          </Row>
        </section>
      </div>
    </section>
  );
};

export default Contact;
